//React
import React from "react"
import { useState, useEffect } from "react"
//Fetch
import axios from "axios"
//Context
import { useAuthContext } from "../../../Hooks/useAuthContext"
//Icons
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
//Notifications
import { toast } from "react-toastify"
//Bootstrap
import { Form } from "react-bootstrap"
//Components
import FormControl from "../../../Components/Forms/FormControl"

export default function CreateEditExternalRequestForm({
  tag,
  setTags,
  tags,
  setShowForm,
  setSelectedTag,
  tagType,
}) {
  const { user } = useAuthContext()
  const [form, setForm] = useState({})
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    if (Object.keys(tag).length > 0) {
      setForm({ name: tag.value })
    }
  }, [tag])

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    })
  }

  const handleSubmit = async (e) => {
    setSubmitting(true)
    e.preventDefault()
    if (Object.keys(tag).length > 0) {
      //Edit
      try {
        const res = await axios.patch(
          `https://${process.env.REACT_APP_IP}/api/tag/${tag.id}`,
          { label: form.name, value: form.name, type: tagType },
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        )
        toast.success("Tag updated.")
        setShowForm(false)

        const updatedTags = tags.map((tag) => {
          if (tag.id === res.data.id) {
            return res.data
          }
          return tag
        })

        setTags(updatedTags)

        setSelectedTag({})
      } catch (err) {
        console.log(err.response)
      } finally {
        setSubmitting(false)
      }
    } else {
      //create
      try {
        const res = await axios.post(
          `https://${process.env.REACT_APP_IP}/api/tag`,
          { label: form.name, value: form.name, type: tagType },
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        )
        toast.success("Tag created.")
        setForm({})
        setShowForm(false)
        setTags((prev) => {
          return [...prev, res.data]
        })
      } catch (err) {
        console.log(err.response)
      } finally {
        setSubmitting(false)
      }
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <div className="mb-3">
        <h4 className="ps-1">
          {Object.keys(tag).length > 0 ? "Edit" : "Create"}{" "}
          {tagType === "CONTACT" ? "contact" : "enquiry"} tag.
        </h4>

        <div className="d-flex flex-column flex-md-row  ">
          <FormControl
            value={form.name}
            label={"Name"}
            field={"name"}
            setField={setField}
            classes={"col"}
            required
          />
        </div>
      </div>

      <div className="d-flex justify-content-end">
        <button type="submit" className="btn btn-primary" disabled={submitting}>
          {!submitting ? (
            "Submit"
          ) : (
            <div>
              Please wait <FontAwesomeIcon icon={faSpinner} spin />
            </div>
          )}
        </button>
      </div>
    </Form>
  )
}
