import axios from "axios";
//React
import React, { useEffect, useState } from "react";
//React Select
import Select from "react-select";
//Bootstrap
import { Form } from "react-bootstrap";
//Global auth via user
import { useAuthContext } from "../../../Hooks/useAuthContext";
//Components
import DistrictSelect from "../../../Components/SelectDropdowns/DistrictSelect";
import ParishSelect from "../../../Components/SelectDropdowns/ParishSelect";

export default function EnquiryFilters({ setPagination, pagination, tagList }) {
  const { user } = useAuthContext();
  const [users, setUsers] = useState([]);
  const [timesheetCodeList, setTimesheetCodeList] = useState([]);

  useEffect(() => {
    const listUsers = async () => {
      try {
        const res = await axios.get(
          `https://${process.env.REACT_APP_IP}/api/users?pageSize=500`,
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        );
        var userSelectList = [];
        res.data.map((user) => {
          userSelectList = [
            ...userSelectList,
            { label: user.firstName + ", " + user.lastName, value: user.id },
          ];
        });
        setUsers(userSelectList);
      } catch (error) {
        console.log(error);
      }
    };

    const listTimesheetCodes = async () => {
      try {
        const res = await axios.get(
          `https://${process.env.REACT_APP_IP}/api/timesheetcodes/TimesheetCode?active=true`,
          {
            headers: { Authorization: `bearer ${user.token}` },
          }
        );
        var timesheetCodeSelectList = [];
        res.data.map((timesheetcode) => {
          timesheetCodeSelectList = [
            ...timesheetCodeSelectList,
            {
              label: `${timesheetcode.code} - ${timesheetcode.name}`,
              value: timesheetcode.id,
            },
          ];
        });
        setTimesheetCodeList(timesheetCodeSelectList);
      } catch (error) {
        console.log(error);
      }
    };

    listTimesheetCodes();
    listUsers();
  }, [user]);

  const setField = (field, value) => {
    setPagination({
      ...pagination,
      [field]: value,
    });
  };

  const handleReset = () => {
    setPagination((prev) => {
      return {
        ...prev,
        district: "",
        parish: "",
        groupType: "",
        interactionStart: "",
        interactionEnd: "",
        state: "Open",
        tags: [],
        queriedBy: "",
        leadForFuture: "",
        timesheetCodeId: "",
      };
    });
  };

  console.log("pagination", pagination);

  return (
    <div className="primaryShadow p-2">
      <Form.Group className="mb-1  col">
        <Form.Label>Current State</Form.Label>
        <Form.Select
          value={pagination.state ?? ""}
          required
          onChange={(e) => setField("state", e.target.value)}
        >
          <option value="">All states</option>
          <option value="Open">Open</option>
          <option value="Closed-Resolved">Closed - Resolved</option>
          <option value="Closed-UnResolved">Closed - Unresolved</option>
        </Form.Select>
      </Form.Group>
      <Form.Group className={`mb-1  p-1 col`}>
        <Form.Label>Tags</Form.Label>
        <Select
          isMulti
          isClearable
          options={tagList}
          value={[...pagination.tags]}
          onChange={(value) => {
            setField("tags", value);
          }}
          placeholder="Select tags..."
        />
      </Form.Group>
      {/* District */}
      <DistrictSelect
        value={pagination.district}
        setField={setField}
        field={"district"}
      />
      {/* Parish */}
      <ParishSelect
        value={pagination.parish}
        setField={setField}
        field={"parish"}
      />

      <Form.Group className={`mb-1 p-1 col`}>
        <Form.Label>Query Taken By</Form.Label>
        <Select
          isClearable
          options={users}
          value={pagination.queriedBy}
          onChange={(value) => {
            setField("queriedBy", value ?? "");
          }}
          placeholder="Select User..."
        />
      </Form.Group>
      <Form.Group className={`mb-1 p-1 col`}>
        <Form.Label>Lead For Future</Form.Label>
        <Select
          isClearable
          options={users}
          value={pagination.leadForFuture}
          onChange={(value) => {
            setField("leadForFuture", value ?? "");
          }}
          placeholder="Select User..."
        />
      </Form.Group>

      <Form.Group className="mb-1 p-1 col">
        <Form.Label>Timesheet Code</Form.Label>
        <Select
          isClearable
          options={timesheetCodeList}
          value={pagination.timesheetCodeId}
          onChange={(value) => {
            setField("timesheetCodeId", value ?? "");
          }}
          placeholder="Select Code..."
        />
      </Form.Group>

      {/* Group Type */}
      {/* <Form.Group className="mb-1  col">
        <Form.Label>Group type</Form.Label>
        <Form.Select
          value={pagination.groupType ?? ""}
          required
          onChange={(e) => setField("groupType", e.target.value)}
        >
          <option value="">All groups</option>
          {ParishList.parishes.map((u) => (
            <option key={u.name} value={u.name}>
              {u.name}
            </option>
          ))}
        </Form.Select>
      </Form.Group> */}
      {/* Date Range */}
      <Form.Group className="mb-2">
        <label>Interaction Date</label>
        <div className="d-flex">
          <Form.Group className="col-6 d-flex flex-column p-1">
            <Form.Label>Start</Form.Label>
            <Form.Control
              type="date"
              value={pagination.interactionStart ?? ""}
                          onChange={(e) => setField("interactionStart", e.target.value)}
            />
          </Form.Group>
          <Form.Group className="col-6 d-flex flex-column p-1">
            <Form.Label>End</Form.Label>
            <Form.Control
              type="date"
              value={pagination.interactionEnd ?? ""}
              onChange={(e) => setField("interactionEnd", e.target.value)}
            />
          </Form.Group>
        </div>
      </Form.Group>
      <div className="d-flex justify-content-end">
        <button className="btn btn-danger" onClick={handleReset}>
          Reset Filters
        </button>
      </div>
    </div>
  );
}
