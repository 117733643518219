
import axios from "axios";
//React
import { React, useState, useEffect } from "react"
import { toast } from "react-toastify"
import { useInView } from "react-cool-inview"
import { useAuthContext } from "../../../Hooks/useAuthContext"
import { useNavigate, useParams } from "react-router-dom";
//Icons
import { faAdd } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"

//Components
import MainContainer from "../../../Components/Containers/MainContainer"
import SideContainer from "../../../Components/Containers/SideContainer"

//Components
import FormCheck from "../../../Components/Forms/FormCheck"
import FormControl from "../../../Components/Forms/FormControl"
//Bootstrap




//Styles
import "./Dashboard.css"
import "../Components/Timesheet.css"
import TimesheetDayView from "../Components/TimesheetDayView"
import ExpensesTableView from "../Components/ExpensesTableView"


export default function Dashboard({ }) {

    const params = useParams();
    const navigate = useNavigate()
    const { user, dispatch } = useAuthContext();
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [currentUser, setCurrentUser] = useState();
    
    const [weekCommencing, setWeekCommencing] = useState(startOfWeek(new Date()));
    Date.prototype.addDays = function (days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    }

    const [mondayEntries, setMondayEntries] = useState([]);
    const [tuesdayEntries, setTuesdayEntries] = useState([]);
    const [wednesdayEntries, setWednesdayEntries] = useState([]);
    const [thursdayEntries, setThursdayEntries] = useState([]);
    const [fridayEntries, setFridayEntries] = useState([]);
    const [weekendEntries, setWeekendEntries] = useState([]);

    const [expensesEntries, setExpensesEntries] = useState([]);
    


    const [timesheetCodes, setTimesheetCodes] = useState([]);
    const [nominalCodes, setNominalCodes] = useState([]);
    const [departmentCodes, setDepartmentCodes] = useState([]);
    const [projectCodes, setProjectCodes] = useState([]);
    const [costCodes, setCostCodes] = useState([]);
   


    const [submitting, setSubmitting] = useState(false);


    const [totalHours, setTotalHours] = useState(0);
    const [totalMiles, setTotalMiles] = useState(0);
    const [totalMileageExpenses, setMileageExpenses] = useState(0);
    const [totalExpenses, setTotalExpenses] = useState(0);

    const [totalByTimesheetCode, setTotalByTimesheetCode] = useState([]);

    useEffect(() => {

        var countHours = 0;
        var countMiles = 0;
        var countExpenses = 0;

        mondayEntries.forEach((entry) => {
            countHours += parseFloat(entry.hours);
            countMiles += parseFloat(entry.miles);
            countExpenses += parseFloat(entry.expenses);
        });
        tuesdayEntries.forEach((entry) => {
            countHours += parseFloat(entry.hours);
            countMiles += parseFloat(entry.miles);
            countExpenses += parseFloat(entry.expenses);
        });
        wednesdayEntries.forEach((entry) => {
            countHours += parseFloat(entry.hours);
            countMiles += parseFloat(entry.miles);
            countExpenses += parseFloat(entry.expenses);
        });
        thursdayEntries.forEach((entry) => {
            countHours += parseFloat(entry.hours);
            countMiles += parseFloat(entry.miles);
            countExpenses += parseFloat(entry.expenses);
        });
        fridayEntries.forEach((entry) => {
            countHours += parseFloat(entry.hours);
            countMiles += parseFloat(entry.miles);
            countExpenses += parseFloat(entry.expenses);
        });
        weekendEntries.forEach((entry) => {
            countHours += parseFloat(entry.hours);
            countMiles += parseFloat(entry.miles);
            countExpenses += parseFloat(entry.expenses);
        });
        setTotalHours(countHours);
        setTotalMiles(countMiles);
        setMileageExpenses(countMiles * 0.45);
        setTotalExpenses(countExpenses);

    }, [mondayEntries, tuesdayEntries, wednesdayEntries, thursdayEntries, fridayEntries, weekendEntries])

    // load the requested timesheet
    useEffect(() => {
        console.log("Loading timesheet " + params.id);
        const getTimesheet = async () => {
            try {
                const res = await axios.get(
                    `https://${process.env.REACT_APP_IP}/api/timesheets/${params.id}`,
                    {
                        headers: { Authorization: `bearer ${user.token}` },
                    }
                ).then((response) => {
                    console.log("Timesheet loaded");

                    let mondayArr = [];
                    response.data.monday.map((entry) => { mondayArr.push({ id: entry.id, date: entry.date, timesheetCode: entry.timesheetCode, task: entry.task, hours: entry.hours, miles: entry.miles, expenses: entry.expenses, enquiryId: entry.enquiryId });});
                    setMondayEntries(mondayArr);

                    let tuesdayArr = [];
                    response.data.tuesday.map((entry) => { tuesdayArr.push({ id: entry.id, date: entry.date, timesheetCode: entry.timesheetCode, task: entry.task, hours: entry.hours, miles: entry.miles, expenses: entry.expenses, enquiryId: entry.enquiryId }); });
                    setTuesdayEntries(tuesdayArr);

                    let wednesdayArr = [];
                    response.data.wednesday.map((entry) => { wednesdayArr.push({ id: entry.id, date: entry.date, timesheetCode: entry.timesheetCode, task: entry.task, hours: entry.hours, miles: entry.miles, expenses: entry.expenses, enquiryId: entry.enquiryId }); });
                    setWednesdayEntries(wednesdayArr);

                    let thursdayArr = [];
                    response.data.thursday.map((entry) => { thursdayArr.push({ id: entry.id, date: entry.date, timesheetCode: entry.timesheetCode, task: entry.task, hours: entry.hours, miles: entry.miles, expenses: entry.expenses, enquiryId: entry.enquiryId }); });
                    setThursdayEntries(thursdayArr);

                    let fridayArr = [];
                    response.data.friday.map((entry) => { fridayArr.push({ id: entry.id, date: entry.date, timesheetCode: entry.timesheetCode, task: entry.task, hours: entry.hours, miles: entry.miles, expenses: entry.expenses, enquiryId: entry.enquiryId }); });
                    setFridayEntries(fridayArr);


                    let weekendArr = [];
                    response.data.weekend.map((entry) => { weekendArr.push({ id: entry.id, date: entry.date, timesheetCode: entry.timesheetCode, task: entry.task, hours: entry.hours, miles: entry.miles, expenses: entry.expenses, enquiryId: entry.enquiryId }); });
                    setWeekendEntries(weekendArr);

                    let expensesArr = [];
                    response.data.expenses.map((entry) => { expensesArr.push({ id: entry.id, nominalCode: entry.nominalCode, projectCode: entry.projectCode, departmentCode: entry.departmentCode, costCode: entry.costCode, pounds: entry.pounds, pence: entry.pence }); });
                    
                    setExpensesEntries(expensesArr);

                    setWeekCommencing(new Date(response.data.weekCommencing));

                    axios.get(`https://${process.env.REACT_APP_IP}/api/user/${response.data.userId}`,
                        {
                            headers: { Authorization: `bearer ${user.token}` },
                        }
                    )
                        .then((response) => {
                            setCurrentUser(response.data)
                        });

                });




            } catch (error) {
                if (error.response.status === 401) {
                    dispatch({ type: "LOGOUT" });
                    navigate("../../expired");
                } else {
                    setError(error.response.status);
                }
            } finally {
                setLoading(false);
            }
        };

        getTimesheet();
    }, [params.id, dispatch, user, navigate]);

    // Generate the timesheet code table
    useEffect(() => {
        let timesheetCodes = [];

        mondayEntries.map((entry) => {
            if (entry.timesheetCode != "") {
                var existing = timesheetCodes.find((x) => x.code == entry.timesheetCode);
                if (existing != undefined)
                    existing.hours += parseFloat(entry.hours);
                else
                    timesheetCodes.push({ code: entry.timesheetCode, hours: parseFloat(entry.hours) });
            }
        });
        tuesdayEntries.map((entry) => {
            if (entry.timesheetCode != "") {
                var existing = timesheetCodes.find((x) => x.code == entry.timesheetCode);
                if (existing != undefined)
                    existing.hours += parseFloat(entry.hours);
                else
                    timesheetCodes.push({ code: entry.timesheetCode, hours: parseFloat(entry.hours) });
            }
        });
        wednesdayEntries.map((entry) => {
            if (entry.timesheetCode != "") {
                var existing = timesheetCodes.find((x) => x.code == entry.timesheetCode);
                if (existing != undefined)
                    existing.hours += parseFloat(entry.hours);
                else
                    timesheetCodes.push({ code: entry.timesheetCode, hours: parseFloat(entry.hours) });
            }
        });
        thursdayEntries.map((entry) => {
            if (entry.timesheetCode != "") {
                var existing = timesheetCodes.find((x) => x.code == entry.timesheetCode);
                if (existing != undefined)
                    existing.hours += parseFloat(entry.hours);
                else
                    timesheetCodes.push({ code: entry.timesheetCode, hours: parseFloat(entry.hours) });
            }
        });
        fridayEntries.map((entry) => {
            if (entry.timesheetCode != "") {
                var existing = timesheetCodes.find((x) => x.code == entry.timesheetCode);
                if (existing != undefined)
                    existing.hours += parseFloat(entry.hours);
                else
                    timesheetCodes.push({ code: entry.timesheetCode, hours: parseFloat(entry.hours) });
            }
        });
        weekendEntries.map((entry) => {
            if (entry.timesheetCode != "") {
                var existing = timesheetCodes.find((x) => x.code == entry.timesheetCode);
                if (existing != undefined)
                    existing.hours += parseFloat(entry.hours);
                else
                    timesheetCodes.push({ code: entry.timesheetCode, hours: parseFloat(entry.hours) });
            }
        });

        setTotalByTimesheetCode(timesheetCodes)
    }, [mondayEntries, tuesdayEntries, wednesdayEntries, thursdayEntries, fridayEntries, weekendEntries])


    

    function startOfWeek(date) {
        // Calculate the difference between the date's day of the month and its day of the week
        var diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);

        // Set the date to the start of the week by setting it to the calculated difference
        return new Date(date.setDate(diff));
    }

    
    const handlePrint = (e) => {
        e.preventDefault();
        window.print();
    };

    

    return (
        <div>
            <SideContainer classes={"d-xl-none"}>

            </SideContainer>
            <MainContainer classes={"d-flex flex-column  p-2"}>
                
                    
                    
       
                <div className="pagebreak">
                    <div className="row">
                        <div className="col-10">
                            <h4 className="my-2">Timesheet - Week Commencing {weekCommencing.toLocaleDateString("en-GB")}
                                <button className="btn btn-primary no-print" onClick={() => navigate(`../edit/${params.id}`)}>Edit</button>
                                <button className="btn btn-primary no-print" onClick={(e) => handlePrint(e)}>Print</button>
                            </h4>
                        </div>
                        <div className="col-2">
                            <button className="btn btn-primary my-2 no-print" onClick={() => navigate('/timesheets')}>Back <FontAwesomeIcon icon={faArrowLeft} />{" "}</button>
                        </div>
                    </div>

                
                    <TimesheetDayView day="Monday" entries={mondayEntries} setEntries={setMondayEntries} timesheetCodes={timesheetCodes} date={weekCommencing.addDays(0)} />
                    <TimesheetDayView day="Tuesday" entries={tuesdayEntries} setEntries={setTuesdayEntries} timesheetCodes={timesheetCodes} date={weekCommencing.addDays(1)} />
                    <TimesheetDayView day="Wednesday" entries={wednesdayEntries} setEntries={setWednesdayEntries} timesheetCodes={timesheetCodes} date={weekCommencing.addDays(2)} />
                    <TimesheetDayView day="Thursday" entries={thursdayEntries} setEntries={setThursdayEntries} timesheetCodes={timesheetCodes} date={weekCommencing.addDays(3)} />
                    <TimesheetDayView day="Friday" entries={fridayEntries} setEntries={setFridayEntries} timesheetCodes={timesheetCodes} date={weekCommencing.addDays(4)} />
                    <TimesheetDayView day="Saturday / Sunday" entries={weekendEntries} setEntries={setWeekendEntries} timesheetCodes={timesheetCodes} date={weekCommencing.addDays(5)} />
                    
                    <hr />
                </div>
                    <div className="row">
                        <div className="col-6"><p><strong>Weekly Target: {currentUser && currentUser.targetHours}</strong></p></div>
                        <div className="col-2 text-end"><p><strong>Total</strong></p></div> 
                        <div className={currentUser && totalHours >= currentUser.targetHours ? "match col-1 text-end" : "mismatch col-1 text-end"}><p><strong>{totalHours} Hrs</strong></p></div>
                        <div className="col-1 text-end"><p><strong>{totalMiles} Miles</strong></p></div>
                        <div className="col-1 text-end"><p><strong>£{totalExpenses.toFixed(2)}</strong></p></div>
                        <div className="col-1"></div>
                    </div>
                    <div className="row">
                        <div className="col-6"><p></p></div>
                        <div className="col-4 text-end"><p><strong>Mileage @ £0.45p per mile</strong></p></div>
                        <div className="col-1 text-end"><p><strong>£{totalMileageExpenses.toFixed(2)}</strong></p></div>
                        <div className="col-1"></div>
                    </div>
                    <div className="row">
                        <div className="col-6"><p></p></div>
                        <div className="col-4 text-end"><p><strong>Total Expenses</strong></p></div>
                        <div className="col-1 text-end"><p><strong>£{totalExpenses.toFixed(2)}</strong></p></div>
                        <div className="col-1"></div>
                    </div>
                    <div className="row">
                        <div className="col-6"><p></p></div>
                        <div className="col-4 text-end"><p><strong>Total Mileage & Expenses</strong></p></div>
                        <div className="col-1 text-end"><p><strong>£{(totalMileageExpenses + totalExpenses).toFixed(2)}</strong></p></div>
                        <div className="col-1"></div>
                    </div>

                <div className="row" style={{ marginTop: 20 }}>
                    <div className="col-12">
                        {/* Expenses Table */}
                        <h4>Expenses</h4>
                        <ExpensesTableView entries={expensesEntries} setEntries={setExpensesEntries} nominalCodes={nominalCodes} projectCodes={projectCodes} departmentCodes={departmentCodes} costCodes={costCodes} expectedTotal={(totalExpenses + totalMileageExpenses).toFixed(2)} />
                    </div>
                </div>

                <div className="row" style={{ marginTop: 20 }}>
                    <div className="col-6">
                        <h4>Timesheet Codes</h4>

                        {/* Timesheet Code Summary */}
                        <div className="row">
                            <div className="col-6">Timesheet Code</div>
                            <div className="col-6">Hours</div>
                        </div>
                        {totalByTimesheetCode.map((entry, index) => (
                            <div key={index} className="row">
                                <div className="col-6">{entry.code}</div>
                                <div className="col-6">{entry.hours}</div>
                            </div>
                        ))}
                    </div>

                   

                </div>


               
                


            </MainContainer>
        </div>
    )
}
