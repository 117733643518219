//React
import React from "react"
import { useEffect, useState } from "react"
import { Route, Routes, useNavigate } from "react-router-dom"
import { useInView } from "react-cool-inview"

//Fetch
import axios from "axios"

//Context
import { useAuthContext } from "../../Hooks/useAuthContext"
//Icons

//Notifications
import { ToastContainer, toast } from "react-toastify"


//Components
import SideContainer from "../../Components/Containers/SideContainer"

import Loading from "../../Components/Loading/Loading"
import { faAdd } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Form } from "react-bootstrap"
import Dashboard from "./Pages/Dashboard"
import Create from "./Pages/Create"
import Edit from "./Pages/Edit"
import View from "./Pages/View"


export default function Events() {
    const { user, dispatch } = useAuthContext()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [createError, setCreateError] = useState(false)
    const [count, setCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [showMore, setShowMore] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [userList, setUserList] = useState([]);
    const [userId, setUserId] = useState(user.id);
    const [weekCommencing, setWeekCommencing] = useState(startOfWeek(new Date()));
    const [weekCommencing2, setWeekCommencing2] = useState(startOfWeek(new Date()).toISOString("en-GB").split('T')[0]);
    const [pagination, setPagination] = useState({search: ""});


    Date.prototype.addDays = function (days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    }

    const handleChangeDate = (e) => {
        console.log(e.target.value)
        var date = new Date(e.target.value)
        console.log(date)
        if (!isNaN(date) && date.getFullYear() > 2020) {
            console.log("date valid")
            setWeekCommencing(date);
            setCreateError(null);
        }
        else {
            console.log("invalid date")
        }
        console.log(e.target.value)
    };

    function startOfWeek(date) {
        // Calculate the difference between the date's day of the month and its day of the week
        var diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);

        // Set the date to the start of the week by setting it to the calculated difference
        return new Date(date.setDate(diff));
    }

    const [timesheets, setTimesheets] = useState([]);
    
    const listTimesheets = async (page, searching) => {
        setRefresh(false)
        let pageNum = 1;
        if (page) {
            pageNum = page;
        } else {
            pageNum = pageNumber;
        }

        //check the pagination.search and remove any commas if present
        const searchStr = pagination.search.replace(/,/g, "");

        try {
            const res = await axios.get(`https://${process.env.REACT_APP_IP}/api/user/${userId}/timesheets?pageNumber=${pageNum}`,{
                    headers: { Authorization: `bearer ${user.token}` },
             });

            const count = res.data.count;
            const results = res.data;

            console.log("REsults:", results)

            setCount(count);

            if (Number(count) <= 0) {
                setShowMore(false);
                setTimesheets([]);
            } else {
                if (results.length === 0) {
                    setShowMore(false);
                } else if (results.length < 15) {
                    if (searching) {
                        setTimesheets(results);
                    } else {
                        setTimesheets((prev) => {
                            return [...prev, ...results];
                        });
                    }
                    setShowMore(false);
                } else {
                    if (searching) {
                        setTimesheets(results);
                    } else {
                        setTimesheets((prev) => {
                            return [...prev, ...results];
                        });
                    }
                    setPageNumber(pageNum + 1);
                    setShowMore(true);
                }
            }
        } catch (error) {
            if (error.response.status === 401) {
                dispatch({ type: "LOGOUT" });
                navigate("../expired");
            } else {
                setError(error.response.status);
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const res = axios.get(
            `https://${process.env.REACT_APP_IP}/api/users/`,
            {
                headers: { Authorization: `bearer ${user.token}` },
            }
        ).then((response) => {
            setUserList(response.data);
        });
    }, []);


    useEffect(() => {
        listTimesheets(1, true);
        // eslint-disable-next-line
    }, [pagination, refresh]);


    useEffect(() => {
        setTimesheets([]);
        listTimesheets(1, true);
        // eslint-disable-next-line
    }, [pagination, refresh, userId]);

    const { observe } = useInView({
        onEnter: () => {
            listTimesheets();
        },
    });
    
    var create = (() => {


        axios
            .post(`https://${process.env.REACT_APP_IP}/api/user/${userId}/timesheets`,
                {
                    WeekCommencing: weekCommencing.toISOString().split('T')[0]
                },
                {
                    headers: { Authorization: `bearer ${user.token}` },
                }

            )
            .then((response) => {
                console.log("response", response)
                navigate(`./edit/${response.data.id}`);
                setRefresh(true);
                
            }).catch((error) => {
                console.log("Timesheet error ",error)
                setCreateError(JSON.stringify(error.response.data));
            }
        );

    });
    

    if (!loading) {
        return error ? (
            <div>Some error..</div>
        ) : (
            <div className="container-xxl">
                    <SideContainer classes={""}>
                        

                        <div className="p-2">
                            <div className="my-2 d-flex justify-content-between align-items-center">
                                <h3 className="text-center">Timesheets</h3>
                                <div>
                                    
                                </div>
                            </div>
                            <div>
                                <h5 className="mb-2">
                                    {user.isAdmin ? (<Form.Group className="mb-1 p-1 col-md-12">
                                        <div className="form-floating">
                                            <Form.Select
                                                value={userId ?? ""}
                                                onChange={(e) => {
                                                    setUserId(e.target.value);
                                                    setCreateError(null);
                                                }}
                                                required
                                            >
                                                <option disabled value="">
                                                    Select user...
                                                </option>

                                                {userList.map((user) => (
                                                    <option
                                                        key={user.id}
                                                        value={user.id}
                                                    >{`${user.firstName} ${user.lastName}`}</option>
                                                ))}
                                                {/* <option value="Other">Other</option> */}
                                            </Form.Select>
                                            <label>
                                                Select User <span className="text-danger">*</span>
                                            </label>
                                        </div>
                                    </Form.Group>) : user.fullName }
                                </h5>
                                <div></div>
                            </div>
                        </div>
                        <div>
                           

                            <div key={0} className="primaryShadow mb-2 p-2  sidebarItem">
                                <h5 className="mb-2">Add Timesheet</h5>
                                <div> <input
                                    type="date"
                                    name="weekCommencing2"
                                    placeholder="Week Commencing"
                                    defaultValue={weekCommencing2}
                                    onChange={handleChangeDate}
                                />
                                    <button className="btn-small btn-primary" onClick={() => create()} style={{marginLeft:20} }>
                                        Create
                                    </button></div>
                                {createError && (<div style={ {color: "red"}}>{createError}</div>) }
                            </div>



                            {timesheets.map((timesheet) => (
                                <div key={timesheet.id} className="primaryShadow mb-2 p-2 hoverMe sidebarItem" onClick={() => navigate(`./view/${timesheet.id}`)}>
                                    <h5 className="mb-2">{new Date(timesheet.weekCommencing).toLocaleDateString("en-GB")}</h5>
                                    <div>Hrs: {timesheet.hours} Exp: £{timesheet.expenses.toFixed(2)} Miles: {timesheet.mileague} mi</div>
                                </div>
                            ))}
                        </div>
                    </SideContainer>



                    <Routes>
                        <Route
                            path="/*"
                            element={
                                <Dashboard userId={userId} />
                            }
                        />
                        <Route
                            path="/create/"
                            element={
                                <Create setRefresh={setRefresh} />
                            }
                        />
                        <Route
                            path="/edit/:id"
                            element={
                                
                                <Edit setRefresh={setRefresh} />
                                
                            }
                        />
                        <Route
                            path="/view/:id"
                            element={

                                <View />

                            }
                        />
                    </Routes>


                <ToastContainer />
            </div>
        )
    } else {
        return <Loading />
    }
}
