//React
import { React, useState, useEffect } from "react"
import { useInView } from "react-cool-inview"
import { useNavigate } from "react-router-dom"
import { useAuthContext } from "../../../Hooks/useAuthContext"
//Icons
import { faAdd } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
//Components
import MainContainer from "../../../Components/Containers/MainContainer"
import SideContainer from "../../../Components/Containers/SideContainer"
import axios from "axios";

//Styles
import "./Dashboard.css"

export default function Dashboard({ userId })
{
    const navigate = useNavigate()
    const { user } = useAuthContext()
    const { observe } = useInView({
        onEnter: () => {
            
        },
    })

    const [timesheetStats, setTimesheetStats] = useState([]); 
    const [loaded, setLoaded] = useState(false);
    const [totalHours, setTotalHours] = useState(0);
    const [totalExpenses, setTotalExpenses] = useState(0);

    useEffect(() => {
        axios.get(
            `https://${process.env.REACT_APP_IP}/api/timesheets/${userId}/stats`,
            {
                headers: { Authorization: `bearer ${user.token}` },
            }
        ).then((response) => {
            console.log("Response: ", response.data)
            setTimesheetStats(response.data);
            setLoaded(true);

            let total = 0;
            response.data.timesheetCodeTotals.map((entry) => {
                total += entry.hours
            });

            setTotalHours(total);

            total = 0;
            response.data.expensesTotals.map((entry) => {
                total += entry.cost
            });

            setTotalExpenses(total);
        });

    }, [userId])

   


    return (
        <div>

            <MainContainer classes={""}>

                    {loaded ? (
                    <div>
                        <div className="row">
                            <h2>{timesheetStats.userName} Timesheet Stats (Period: {new Date(timesheetStats.periodStartDate).toLocaleDateString("en-GB")})</h2>
                        </div>


                        <div className="row" style={{marginTop: 40} }>
                            {/* Hours (This Month) */ }
                            <div className="col-6">
                                <div className="primaryShadow">
                                    <div className="row mb-3">
                                        <div className="col-12"><h4>Hours (This Period)</h4></div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-4">
                                            <h4>Target</h4>
                                            <h3 className="innerCon  primary text-center">{timesheetStats.targetHours}</h3>
                                        </div>
                                        <div className="col-4">
                                            <h4>Recorded</h4>
                                            <h3 className={timesheetStats.hoursRecorded >= timesheetStats.targetHours ? "innerCon text-center success" : "innerCon text-center fail" }  >{timesheetStats.hoursRecorded}</h3>

                                        </div>
                                        <div className="col-4">
                                            <h4>TOIL</h4>
                                            <h3 className={timesheetStats.hoursDifference >= 0 ? "innerCon  text-center success" : "innerCon  text-center fail"}  >{timesheetStats.hoursDifference}</h3>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Holidays (This Period) */}
                            <div className="col-6">
                                <div className="primaryShadow">
                                    <div className="row mb-3">
                                        <div className="col-12"><h4>Holidays (This Period)</h4></div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-4">
                                            <h4>Allowance</h4>
                                            <h3 className="innerCon primary text-center">{timesheetStats.holidayAllowance}</h3>
                                        </div>
                                        <div className="col-4">
                                            <h4>Used</h4>
                                            <h3 className="innerCon primary text-center">{timesheetStats.holidaysUsed}</h3>
                                        </div>
                                        <div className="col-4">
                                            <h4>Remaining</h4>
                                            <h3 className={timesheetStats.holidaysRemaining > 0 ? "innerCon  text-center primary" : "innerCon  text-center fail"} >{timesheetStats.holidaysRemaining}</h3>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>







                        <div className="row" style={{ marginTop: 40 }}>
                            {/* Total Hours */}
                            <div className="col-5">
                                <div className="primaryShadow">
                                    <div className="row ">
                                        <div className="col-12">
                                            <h4>Total Hours (Current Month)</h4>
                                        </div>
                                    </div>

                                    <div className="row" style={{ marginBottom: 10 }}>
                                        <div className="col-8">
                                            <strong>Timesheet Code</strong>
                                        </div>
                                        <div className="col-4 text-end">
                                            <strong>Hours</strong>
                                        </div>
                                    </div>
                                           

                                    {timesheetStats.timesheetCodeTotals.length > 0 ? timesheetStats.timesheetCodeTotals.map((entry) => (
                                        <div className="row">
                                            <div className="col-8">
                                                {entry.timesheetCode}
                                            </div>
                                            <div className="col-4 text-end">
                                                {entry.hours}
                                            </div>
                                        </div>)) :
                                        (<div className="row"><div className="col-12">No Timesheets Found</div></div>)
                                    }

                                    <div className="row" style={{borderTop: "1px solid #000"} }>
                                        <div className="col-8">
                                            <strong>Total</strong>
                                        </div>
                                        <div className="col-4 text-end">
                                            <strong>{totalHours}</strong>
                                        </div>
                                    </div>

                                        
                                       
                                </div>
                            </div>
                            {/* Total Expenses */}
                            <div className="col-7">
                                <div className="primaryShadow">
                                    <div className="row">
                                        <div className="col-12">
                                            <h4>Total Expenses (Current Month)</h4>
                                        </div>
                                    </div>

                               
                                    <div className="row" style={{marginBottom: 10} }>
                                        <div className="col-2">
                                            <strong>Nominal</strong>
                                        </div>
                                        <div className="col-3">
                                            <strong> Dept No</strong>
                                        </div>
                                        <div className="col-2">
                                            <strong>Project</strong>
                                        </div>
                                        <div className="col-3">
                                            <strong>Costs Code</strong>
                                        </div>
                                        <div className="col-2 text-end">
                                            <strong>£</strong>
                                        </div>
                                    </div>

                                    {timesheetStats.expensesTotals.length > 0 ? timesheetStats.expensesTotals.map((entry) => (
                                        <div className="row">
                                            <div className="col-2">
                                                {entry.nominalCode}
                                            </div>
                                            <div className="col-3">
                                                {entry.departmentCode}
                                            </div>
                                            <div className="col-2">
                                                {entry.projectCode}
                                            </div>
                                            <div className="col-3">
                                                {entry.costCode}
                                            </div>
                                            <div className="col-2 text-end">
                                                {entry.cost}
                                            </div>
                                        </div>
                                        )) : (
                                        <div className="row">
                                            <div className="col-12">No Expenses Found</div>
                                        </div>)
                                    }


                                    <div className="row" style={{ borderTop: "1px solid #000" }}>
                                        <div className="col-10">
                                            <strong>Total</strong>
                                        </div>
                                        <div className="col-2 text-end">
                                            <strong>{totalExpenses}</strong>
                                        </div>
                                    </div>
                                        
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                        
                    ) : (<div>Loading</div>)}
            </MainContainer>
        </div>
    )
}
